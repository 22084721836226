import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { MessengerComponent } from '../shared/messenger/messenger.component';

@Component({
  selector: 'app-messenger-normal',
  templateUrl: './messenger-normal.component.html',
  styleUrls: ['./messenger-normal.component.scss']
})
export class MessengerNormalComponent implements OnInit {
  @ViewChild(MessengerComponent) elementChat: MessengerComponent;
  
  constructor() { }

  ngOnInit() {

  }

}
