import { Injectable } from '@angular/core';


export enum states {
  REGISTER,
  CONVERSATION
};

export enum registerConversation{
  WELCOME,
  MOBILENUMER,
  CODE,
  NAME
};

export enum stateServer{
  MOBILENUMER_RESULT,
  CODE_RESULT,
  AUTH_RESULT,
  MSG_RESULT,
  
}

@Injectable({
  providedIn: 'root'
})

export class RegisterService {

  constructor() { }
}
