import { Component, ElementRef, ViewChild, OnInit, Output, HostListener, Input } from '@angular/core';
import { Observable, of, Subject, Subscriber, observable, Subscription } from 'rxjs';
import { MatDrawer, MatDrawerContainer, MatToolbar } from '@angular/material';
import { FormControl } from '@angular/forms';
import { EventEmitter } from 'events';
import { RegisterService, states, registerConversation, stateServer } from '../services/register.service';
import { Socket } from 'ngx-socket-io';
import { AuthService } from '../services/auth.service';
@Component({
  selector: 'base-messenger',
  templateUrl: './messenger.component.html',
  styleUrls: ['./messenger.component.scss']
})



export class MessengerComponent implements OnInit {
  opened = false;
  // @Output() msgEvent = new EventEmitter();
  msgEvent = new Subject();
  msgRegisterSub = new Subscription;
  @ViewChild('chat') elementChat: ElementRef;
  @ViewChild('container') elementContainer: ElementRef;
  @ViewChild('toolbar') elementtoolbar: MatToolbar;

  @ViewChild('drawer') drawerRef: MatDrawer;
  @Input('attendant') attendantMode: boolean = false;

  input = new FormControl('');
  isTyping = false;
  currentState = states.REGISTER;
  currentRegisterState = registerConversation.WELCOME;
  authDone = false;
  focusInput = false;
  constructor(private socket: Socket,
    private auth: AuthService,
    public el: ElementRef) {
  }

  msgs = [
    // { data: 'Ola tudo bem?', sent: 0 },

  ]
  reset(){
    this.msgs.splice(0,this.msgs.length);
  }
  ngOnInit() {
    this.input.registerOnChange(this.ontextChange)

    // this.auth.login(
    //   'react', 'express'
    // ).subscribe(x => {
    //   console.log('auth return x ', x);

    //   if (x.token) {
    //     this.samplesocket(x.token);
    //   }
    // });
    this.socket.connect();
    this.socket.on('connect', () => {

      if (this.attendantMode) {
        this.socket.emit('auth_attendant', 'vitor');

        console.log('mode existe');

        this.currentState = states.CONVERSATION;


      } else {


        let i = localStorage.getItem('token');
        if (i != null) {
          console.log('usuario existe');

          this.socket.emit('auth', i);


        } else {
          console.log('usuario nao existe')
          // this.socket.on('auth', function(user){
          // localStorage.setItem('token', user);
          // });
          // this.msgs.push({ data: 'Ola tudo bem?', sent: 0 });
          this.addMsg(0, "Ola tudo bem?")
          if (this.currentState == states.REGISTER) {

            this.msgRegisterSub = this.msgEvent.subscribe(x => { this.handleBot() });
            // this.msgEvent.on('sent_msg', x => { this.handleBot() });
          }
        }
      }
    })
    // this.samplesocket('0fa');
    this.socket.on('recv', x => { this.receive(x) });
    this.socket.on('mymsgs', data => {
      console.log(data);
      data.msgs.forEach(msg => {
        // let newmsg = { data: (msg.body), sent: msg.user };
        // this.msgs.push(newmsg);
        this.addMsg(msg.user, msg.body)
      })
    })

  }
  toggle() {
    this.drawerRef.toggle();
    console.log(this.opened);
  }
  // ngAfterViewInit(){
  //   let chat_fixed_h:number =  document.getElementById("chat-fixed").clientHeight;
  //   let tool_h:number =  this.elementtoolbar._elementRef.nativeElement.clientHeight;
  //   // document.getElementById("container").style.height= ( window.innerHeight - chat_fixed_h) + "px";
  //   document.getElementById("chat").style.height= ( window.innerHeight - chat_fixed_h - tool_h) + "px";
  //   // this.elementContainer.nativeElement.height = 100;
  // }
  updateChatHeightBase() {
    let chat_fixed_h: number = document.getElementById("chat-fixed").clientHeight;
    let tool_h: number = this.elementtoolbar._elementRef.nativeElement.clientHeight;
    let doc_h: number = this.el.nativeElement.height;
    let chat_fixed_size = this.el.nativeElement.offsetTop * 0.20;
    // document.getElementById("container").style.height= ( window.innerHeight - chat_fixed_h) + "px";
    console.log(document.getElementById("chat-fixed").offsetHeight);
    // document.getElementById("chat").style.height= ( this.el.nativeElement.offset() - chat_fixed_h - tool_h) + "px";
    // document.getElementById("chat-fixed").style.height= chat_fixed_size+ "px";
    document.getElementById("chat").style.height = (document.getElementById("chat-fixed").offsetTop - tool_h) + "px";
    console.log('current',  document.getElementById("chat").style.height,'new',(document.getElementById("chat-fixed").offsetTop - tool_h) + "px")
    // this.elementContainer.nativeElement.height = 100;
  }
  @HostListener('click', ['$event']) clickedOutside($event ){
    event.preventDefault();
    event.stopPropagation();
  }
  receive(data) {

    console.log(data);
    let msg = "";
    switch (data.state) {
      case stateServer.MOBILENUMER_RESULT:
        console.log("mobile res ", data);
        if (data.msg.error != null) {
          msg = "O numero parece estar incorreto, digite novamente porfavor!";
        } else {

          setTimeout(() => {

            this.receive('Enviamos um codigo para confirmação');
            this.receive('Digite-o para proseguir');
            this.isTyping = false;
            this.currentRegisterState = registerConversation.CODE;

          }, 1000);


        }
        break;
      case stateServer.CODE_RESULT:
        if (data.msg.error != null) {
          msg = "Codigo esta incorrect, digite novamente porfavor!";
          this.isTyping = false;

          this.currentRegisterState = registerConversation.CODE;
        } else {


          msg = 'Codigo verificado com sucesso';
          this.isTyping = false;
          this.msgRegisterSub.unsubscribe();
          this.currentState = states.CONVERSATION;
          console.log("code res ", data);

          localStorage.setItem('token', data.msg.token)
          this.socket.emit('auth', data.msg.token);
        }
        break;
      case stateServer.AUTH_RESULT: {
        if (data.msg.error != null) {
          msg = "Nao consiguimos autenticar essa sessão, entre com seu numero novamente!";
          this.isTyping = false;
          console.log(data.msg);
          this.currentState = states.REGISTER;
          this.currentRegisterState = registerConversation.MOBILENUMER;
          this.msgRegisterSub = this.msgEvent.subscribe(x => { this.handleBot() });

        } else {
          this.currentState = states.CONVERSATION;

          msg = "Seja bem vindo!";


        }
      } break;
      default:
        if (data.msg)
          msg = data.msg; else msg = data;
        break;
    }

    let newmsg = { data: (msg), sent: 0 };
    if (msg.length) {
      this.addMsg(0, msg)
    }
    //     this.msgs.push(newmsg);

  }
  addMsg(user, body) {
    let msg = {
      data: body,
      sent: user
    }
    this.msgs.push(msg);

    // this.msgEvent.next(msg);

    // this.toggleInputFocus();

    setTimeout(() => {

      this.elementChat.nativeElement.scrollTop = this.elementChat.nativeElement.scrollHeight;
    }, 100)
  }
  send() {
    // this.msgs.push(this.input.value);
    // this.msgs.push(this.input.value);
    //    this.socket.emit('msg', { msg:'' });
    if (this.input.value.length
    ) {



      var msg = { data: (this.input.value), sent: 1 };





      if (this.currentState == states.CONVERSATION) {

        this.socket.emit('send', msg);
      }
      this.msgEvent.next(msg);

      // this.msgs.push(msg);
      this.addMsg(1, this.input.value)
      this.input.setValue('');
      this.toggleInputFocus();
      // document.getElementById("chat").scrollTop =  document.getElementById("chat").scrollHeight;



      //  console.log( this.elementChat.nativeElement.scrollHeight = );
    }
  }


  // samplesocket(token) {
  //   this.socket.emit('authenticate', { token: token });

  //   this.socket.on('authenticated', x => {
  //     this.authDone = true;
  //     console.log('logado');
  //     //  this.msgRegisterSub.unsubscribe();
  //   });
  // }
  handleBot() {




    if (this.isTyping)
      return;
    this.isTyping = true;
    switch (this.currentRegisterState) {
      case registerConversation.WELCOME:
        setTimeout(() => {
          // this.receive('Poderia digitar seu nome completo?')
          this.addMsg(0, 'Poderia digitar seu nome completo?')
          this.isTyping = false;

          this.currentRegisterState = registerConversation.NAME;
        }, 1000);
        break;

      case registerConversation.NAME:
        var msg = { data: (this.input.value), sent: 1 };
        this.isTyping = false;
        console.log(msg);
        this.socket.emit('send', {
          registerStates: registerConversation.NAME,
          msg: msg.data
        });
        setTimeout(() => {
          this.addMsg(0, 'Agora digite seu numero de celular?')

          // this.receive('Agora digite seu numero de celular?')

          this.currentRegisterState = registerConversation.MOBILENUMER;
        }, 1000);
        break;
      case registerConversation.MOBILENUMER:


        this.isTyping = false;

        var msg = { data: (this.input.value), sent: 1 };
        this.socket.emit('send', {
          states: this.currentState,
          registerStates: this.currentRegisterState,
          msg: msg.data
        });


        break;
      case registerConversation.CODE:
        this.isTyping = false;

        // var msg = { data: (this.input_ddd.value+this.input_cel.value), sent: 1 };
        // this.input_ddd.setValue('');
        // this.input_cel.setValue('');
        var msg = { data: (this.input.value), sent: 1 };
        this.socket.emit('send', {
          states: this.currentState,
          registerStates: registerConversation.CODE,
          msg: msg.data
        });

        // if (1) {
        //   setTimeout(() => {
        //     this.receive('Codigo verificado com sucesso');
        //     this.isTyping = false;
        //     this.msgRegisterSub.unsubscribe();
        //     this.currentState = states.CONVERSATION;
        //   }, 1000);

        // }
        // else {
        //   setTimeout(() => {
        //     this.isTyping = false;

        //     this.receive('Codigo errado');
        //     this.receive('Digite novamente');
        //     this.currentRegisterState = registerConversation.CODE;


        //   }, 1000);
        // }
        // return 1;

        break;

      default:
        break;
    }

  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    event.target.innerWidth;
    this.updateChatHeightBase();
  }
  ngAfterViewInit() {
    this.updateChatHeightBase();
  }

  toggleInputFocus() {
    if (!this.input.value.length)

      this.focusInput = this.focusInput ? false : true;
  }
  ontextChange() {

  }


}
