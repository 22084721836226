import { Component, ElementRef, ViewChild } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDrawer, MatDrawerContainer } from '@angular/material';
import { FormControl } from '@angular/forms';
import { Overlay, OverlayConfig } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Socket } from 'ngx-socket-io';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'umsg';

  constructor(private socket: Socket) { }
}
