import { Component, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BASE_URL, SocketDash } from '../base';
import { finalize } from 'rxjs/operators'
import { SockAuthService } from '../auth-service.service';


class authResponse {
  token: string
}

@Component({
  selector: 'app-dash-login',
  templateUrl: './dash-login.component.html',
  styleUrls: ['./dash-login.component.scss']
})
export class DashLoginComponent implements OnInit {
  email = new FormControl('exemplo@gmail.com', [Validators.required, Validators.email]);
  passwd = new FormControl('', Validators.required);
  formErr: boolean = false;

  // ngAfterContentInit() {
  //   if (localStorage.getItem('token')) {

  //     this.spinner.show().then(() => {


  //       console.log('sot')
  //       setTimeout(() => {

  //         this.router.navigate(['/dashboard']);
  //         this.spinner.hide();
  //       }, 1000);

  //     });

  //   }
  // }

  ngOnInit() {

    if (!localStorage.getItem('token')) {
      this.sock.connect();
      // this.sock.emit('user', {'null':0});

      this.sock.on('auth_status', (data) => {
        let token = data.token;
        console.log('auth return ', token)
        if (token != null) {
          this.formErr = false;


          setTimeout(() => {

            localStorage.setItem('token', token)



            this.router.navigate(['/dashboard']);
            this.spinner.hide();
          }, 2000);
        } else {
          this.formErr = true;



          setTimeout(() => {
            this.spinner.hide()

          }, 2000);
        }
      })

    } else {

      this.router.navigate(['/dashboard']);


    }
  }



  constructor(private spinner: NgxSpinnerService,

    private router: Router,
    // private http: HttpClient,
    private sock: SocketDash,
    // private auth: SockAuthService

  ) { }

  submit() {
    this.spinner.show();



    // this.sock.emit('auth', {
    //   email: this.email.value,
    //   passwd: this.passwd.value,
    // })
    // this.formErr = false;

    console.log('init')
    setTimeout(() => {

      localStorage.setItem('token', '132456123')



      this.router.navigate(['/dashboard']);
      this.spinner.hide();
    console.log('end')

    }, 2000);


    // this.http.post<authResponse>(BASE_URL + '/dashboard/auth', {
    //   email: this.email.value,
    //   passwd: this.passwd.value,
    // }).pipe(finalize(() => {

    // })).
    //   subscribe(success => {
    //     console.log('ok', success)
    //     setTimeout(() => {
    //       this.formErr = false;
    //       localStorage.setItem('token', success.token)



    //       this.router.navigate(['/dashboard']);
    //       this.spinner.hide().then(x => {

    //       })

    //     }, 2000);


    //   }, err => {
    //     this.formErr = true;


    //     console.log('erro ')
    //     console.log(err)
    //     setTimeout(() => {
    //       this.spinner.hide()

    //     }, 2000);
    //   });
  }

}
