import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MessengerComponent } from './shared/messenger/messenger.component';
import { AppComponent } from './app.component';
import { FirstPageComponent } from './first-page/first-page.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { DashMainComponent } from './dashboard/dash-main/dash-main.component';
import { DashUsersComponent } from './dashboard/dash-users/dash-users.component';
import { DashCampaignsComponent } from './dashboard/dash-campaigns/dash-campaigns.component';
import { DashMessengerComponent } from './dashboard/dash-messenger/dash-messenger.component';
import { DashLeadComponent } from './dashboard/dash-lead/dash-lead.component';
import { MessengerNormalComponent } from './messenger-normal/messenger-normal.component';
import { DashLoginComponent } from './dash-login/dash-login.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';

const routes: Routes = [
  {
    path: 'home',
    component: FirstPageComponent,
  },
  {
    path: '',
    redirectTo: '/home',
    pathMatch: 'full'
  },
  {
    path: 'msg', component: MessengerNormalComponent
  },
  {
    path: 'dashboard',
    loadChildren: './dashboard/dashmodule.module#DashmoduleModule'
  },
  {
    path: 'login',
    component: DashLoginComponent
  },
  { path: '**', component: PageNotFoundComponent }

  // {
  //   path: 'msg/:id/dashboard',
  //   component: DashboardComponent,
  //   children:[
  //     {

  //       path: 'principal',
  //       component: DashMainComponent,
  //     },
  //     {

  //       path: 'usuarios',
  //       component: DashUsersComponent,
  //     },
  //     {

  //       path: 'sms',
  //       component: DashLeadComponent,
  //     },
  //     {

  //       path: 'mensageiro',
  //       component: DashMessengerComponent,
  //     },  
  //     {

  //       path: 'campanhas',
  //       component: DashCampaignsComponent,
  //     },  
  //   ]
  //   }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
