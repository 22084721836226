import { SocketIoConfig, Socket } from 'ngx-socket-io';
import { Injectable } from '@angular/core';

export const BASE_URL = "http://localhost:3000"
export const config: SocketIoConfig = { url: BASE_URL, options: {autoConnect:false, reconnection:false, path: "/messenger"} };

@Injectable()
export class SocketDash extends Socket {
 
    constructor() {
        super({ url: BASE_URL,options: {autoConnect:false, reconnection:false, path: "/dashboard_socket"}});
    }
 
}