import { BrowserModule } from '@angular/platform-browser';
import { NgModule, Injectable } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MaterialModule } from './material-module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FirstPageComponent } from './first-page/first-page.component';

import { SocketIoModule, SocketIoConfig, Socket } from 'ngx-socket-io';
import { HttpClientModule } from '@angular/common/http';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { DashboardComponent } from './dashboard/dashboard.component';
import { LoginComponent } from './login/login.component';
import { RegisterComponent } from './register/register.component';
import { DashLeadComponent } from './dashboard/dash-lead/dash-lead.component';
import { DashImportComponent } from './dashboard/dash-import/dash-import.component';
import { DashUsersComponent } from './dashboard/dash-users/dash-users.component';
import { DashClientsComponent } from './dashboard/dash-clients/dash-clients.component';
import { DashCampaignsComponent } from './dashboard/dash-campaigns/dash-campaigns.component';
import { DashMainComponent } from './dashboard/dash-main/dash-main.component';
import { DashMessengerComponent } from './dashboard/dash-messenger/dash-messenger.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { MessengerNormalComponent } from './messenger-normal/messenger-normal.component';
import { SharedModule } from './shared/shared.module';
import { DashLoginComponent } from './dash-login/dash-login.component';
import { NgxSpinnerModule } from 'ngx-spinner';




import { JwtModule, JwtHelperService, JWT_OPTIONS } from '@auth0/angular-jwt';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { config, SocketDash } from './base';
// import { config, SocketDash } from './base';
export function tokenGetter() {
  return localStorage.getItem('token');
}



@NgModule({
  declarations: [
    AppComponent,
    // MessengerComponent,
    FirstPageComponent,
    // DashboardComponent,
    LoginComponent,
    RegisterComponent,
    MessengerNormalComponent,
    DashLoginComponent,
    PageNotFoundComponent,
    // DashLeadComponent,
    // DashImportComponent,
    // DashUsersComponent,
    // DashClientsComponent,
    // DashCampaignsComponent,
    // DashMainComponent,
    // DashMessengerComponent,
  ],

  imports: [
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    MaterialModule,
    AppRoutingModule,
    FlexLayoutModule,
    SocketIoModule.forRoot(config),
    HttpClientModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    SharedModule,
    NgxSpinnerModule,
    JwtModule.forRoot({
      config:{
        
        tokenGetter: tokenGetter,
        whitelistedDomains: ['localhost:3000'],
        blacklistedRoutes:  ['localhost:3000/dashboard/auth']
      }
        
    })
  ],
  // exports: [ MessengerComponent],
  providers: [
    JwtInterceptor,
    SocketDash
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
