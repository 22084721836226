import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Endpoint } from './endpoint';


export interface AuthModel{
  token,
  username
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http:HttpClient) { }

  login(username, passwd){
    return this.http.post<AuthModel>(
      Endpoint.URL+"/auth/login",
      {
        username: username,
        password : passwd
      }
    )
  }
}
